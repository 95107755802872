
<template>
  <!-- 商品秒杀页 -->
  <div class="commodity-spike" v-if="isLoading">
    <van-nav-bar
      title="砍价详情"
      fixed
      left-text=""
      left-arrow
      @click-left="$goBack()"
      @touchmove.prevent
    />

    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div
          class="remainning-time"
          v-if="goodsList.bargainUser && goodsList.bargainUser.status !== '2'"
        >
          倒计时：<span>{{ time }}</span>
        </div>

        <div class="warp">
          <div class="goods-list">
            <!-- 秒杀商品列表 -->
            <div class="goods-item">
              <div class="title">
                <!-- 用户点击跳转时间 传店铺id -->
                <img
                  @click="JumpStore()"
                  class="brand-logo"
                  :src="goodsList.shopInfo.imgUrl"
                  alt="品牌logo"
                />
                <span class="store-name"> {{ goodsList.shopInfo.name }}</span>
              </div>

              <div class="good-desc">{{ goodsList.name }}</div>

              <div class="join-poeple">
                <span class="poeple-num"
                  >已有{{ goodsList.launchNum }}人参加</span
                >
                <span class="rule" @click="openRule()"> 砍价规则 </span>
              </div>

              <!-- 砍价规则 -->
              <div class="rule-info" v-show="isShow" @touchmove.prevent>
                <div class="rule-box fade-out-fwd">
                  <div class="rule-title">
                    规则说明 <span class="close" @click="closeRule()">X</span>
                  </div>
                  <div class="rule-content">{{ goodsList.cutRule }}</div>
                </div>
              </div>
              <div class="good-info">
                <img class="good-img" :src="goodsList.picUrl" alt="商品图片" />
                <div class="desc">
                  <span class="good-name van-multi-ellipsis--l2">{{
                    goodsList.goodsSpu.name
                  }}</span>
                  <span class="specifications">
                    <div
                      class="specifications-item"
                      v-for="item in goodsList.goodsSku.specs"
                      :key="item.id"
                    >
                      {{ item.specValueName }}
                    </div>
                  </span>
                  <div class="prices">
                    <span class="activeprice">
                      ￥{{ goodsList.bargainPrice }}
                    </span>
                    <span class="orginprice"
                      >￥{{ goodsList.goodsSku.salesPrice }}</span
                    >
                    <div
                      class="lable"
                      v-if="
                        goodsList.goodsSpu &&
                        goodsList.goodsSpu.freightTemplat &&
                        goodsList.goodsSpu.freightTemplat.name
                      "
                    >
                      {{ goodsList.goodsSpu.freightTemplat.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!goodsList.bargainUser && goodsList.status === '1'"
                class="robbing"
                @click="getId()"
              >
                马上砍价
              </div>
              <div
                v-if="!goodsList.bargainUser && goodsList.status === '0'"
                class="robbing"
                style="background-color: gery"
              >
                活动已结束
              </div>
              <div
                v-if="
                  goodsList.bargainUser && goodsList.bargainUser.status === '2'
                "
                class="robbing"
                style="background-color: gery"
              >
                活动已过期
              </div>

              <div
                v-if="!goodsList.bargainUser && goodsList.status === '2'"
                class="robbing"
              >
                活动未开始
              </div>
              <div
                v-if="
                  goodsList.bargainUser &&
                  goodsList.bargainUser.status === '0' &&
                  goodsList.status === '1'
                "
                @click="showSharePual()"
                class="robbing"
              >
                邀请好友帮砍
              </div>
              <div
                v-if="
                  goodsList.bargainUser &&
                  goodsList.bargainUser.status === '1' &&
                  goodsList.status === '1'
                "
                class="robbing"
                style="background-color: #ffc698"
              >
                砍价已完成
              </div>
              <div class="time">
                {{ goodsList.validBeginTime }}至{{ goodsList.validEndTime }}
              </div>
            </div>
            <div class="bargain-info" v-if="goodsList.bargainUser">
              <div class="bargain-data">
                已砍<span>{{ goodsList.bargainUser.havBargainAmount }}</span>
                元，还差
                <span>{{
                  (
                    goodsList.goodsSku.salesPrice -
                    goodsList.bargainUser.havBargainAmount -
                    goodsList.bargainPrice
                  ).toFixed(2)
                }}</span
                >元
              </div>
              <div class="proportion">
                <div
                  class="proportion-item"
                  :style="{
                    width:
                      (
                        (goodsList.bargainUser.havBargainAmount /
                          (goodsList.goodsSku.salesPrice -
                            goodsList.bargainPrice)) *
                        100
                      ).toFixed(2) + '%',
                  }"
                >
                  <div class="readyhave">
                    {{
                      (
                        (goodsList.bargainUser.havBargainAmount /
                          (goodsList.goodsSku.salesPrice -
                            goodsList.bargainPrice)) *
                        100
                      ).toFixed(2) + "%"
                    }}
                  </div>
                </div>
              </div>
              <div
                class="over"
                v-if="
                  goodsList.bargainUser &&
                  goodsList.bargainUser.status === '1' &&
                  goodsList.status === '1'
                "
                @click="getGood()"
              >
                ￥{{ goodsList.bargainPrice }}砍价后购买
              </div>
            </div>
          </div>
        </div>
        <div class="frends" v-if="cutFrends.length">
          <div class="title">
            <div class="left"></div>
            砍价亲友团
          </div>
          <div class="hlep-list">
            <div
              :class="[
                'hlep-item',
                {
                  border:
                    index != 2 && index != cutFrends.slice(0, 3).length - 1,
                },
              ]"
              v-for="(item, index) in cutFrends.slice(0, 3)"
              :key="item.id"
            >
              <img class="frends-img" :src="item.headimgUrl" alt="" />
              <div class="frends-name">{{ item.nickName }}</div>
              <div class="frends-help-money">
                ￥ <span>{{ item.cutPrice }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="footer" v-if="goodsList.goodsSpu.description">
          <div class="title">商品信息</div>
          <div class="richtext" v-html="goodsList.goodsSpu.description">
            <span>已经到底了</span>
          </div>
        </div>
      </van-pull-refresh>
    </div>
    <van-share-sheet
      v-model="showShare"
      :options="options"
      title="立即分享给好友"
      @select="onSelect"
      cancel-text=""
    />
  </div>
</template>

<script>
// import bargainList from '../../api/Bargain/bargin';
// import { Toast } from 'vant';
import {
  BargainDetail,
  helpCut,
  setStatus,
} from "../../api/BargainDetail/BargainDetil";

export default {
  data() {
    return {
      goodsList: {}, //页面砍价详情数据
      isShow: 0, // 砍价规则的状态
      shareClass: { title: "", url: "", num: "", openUrl: "" },
      isLoading: false,
      time: "", //砍价时间倒计时
      cutFrends: [], //帮砍好友列表
      showShare: false, //分享面板的状态
      data: {},
      options: [
        { name: "微信", icon: "wechat", index: 0 },
        { name: "朋友圈", icon: "wechat-moments", index: 1 },
        { name: "QQ", icon: "qq", index: 2 },
      ],
    };
  },
  created() {
    //判断是否传id过来  没传跳转到砍价列表页
    if (this.$route.query.session) {
      this.$store.commit(
        "setSession",
        this.$route.query.session ||
          "$2a$10$yLACX3C0ffNNxgos1Xb8S.zk7GAREXRETdvzIcLB4OThHRaYhNXZu"
      );
    }
    this.getUser();
    if (!this.$route.query.id) {
      this.$store.commit("delRouter");
      this.$router.replace("/Bargain");
    }
    //有id则请求详情数据
    this.getBarginInfo();
  },

  methods: {
    onRefresh() {
      location.reload();
    },
    //显示分享面板
    showSharePual() {
      this.showShare = true;
    },
    //判断用户点击的分享途径
    onSelect(data, index) {
      this.shareArea(this.options[index].index);
    },

    getTime(index) {
      if (index === this.active) {
        return;
      }
      this.active = index;
      this.getActive;
    },
    //砍价的倒计时方法
    getYMDHMS(timestamp) {
      timestamp || 0;
      timestamp = timestamp.replace(/-/gi, "/");
      let nowTime = Date.parse(new Date()) / 1000;
      let endTime = Date.parse(timestamp) / 1000;
      let t = endTime - nowTime;
      let day = Math.floor(t / 60 / 60 / 24);
      let hour = Math.floor((t / 60 / 60) % 24);
      let min = Math.floor((t / 60) % 60);
      let sec = Math.floor(t % 60);
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (min < 10) {
        min = "0" + min;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }
      this.time = day + "天" + hour + "时" + min + "分" + sec + "秒";
      setInterval(() => {
        let nowTime = Date.parse(new Date()) / 1000;
        let endTime = Date.parse(timestamp) / 1000;
        let t = endTime - nowTime;
        let day = Math.floor(t / 60 / 60 / 24);
        let hour = Math.floor((t / 60 / 60) % 24);
        let min = Math.floor((t / 60) % 60);
        let sec = Math.floor(t % 60);
        if (hour < 10) {
          hour = "0" + hour;
        }
        if (min < 10) {
          min = "0" + min;
        }
        if (sec < 10) {
          sec = "0" + sec;
        }
        this.time = day + "天" + hour + "时" + min + "分" + sec + "秒";
      }, 1000);
      this.isLoading = true;
    },

    getId() {
      //用户点击砍价
      setStatus({ bargainId: this.goodsList.id }).then((res) => {
        if (res.data.code === 0) {
          //
          if (res.data.data) {
            this.getBarginInfo();
          }
        }
      });
    },
    JumpStore() {
      //获取店铺id 并跳转到店铺首页
    },
    closeRule() {
      //砍价规则的开关
      this.isShow = 0;
    },
    openRule() {
      this.isShow = 1;
    },
    setRem() {
      //设置跟字体大小
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
    // 获取砍价商品详情的方法
    getBarginInfo() {
      if (!this.$route.query.id) {
        return;
      }
      //砍价详情api
      BargainDetail({ bargainId: this.$route.query.id }).then((res) => {
        if (res.data.code === 0) {
          this.goodsList = res.data.data;
          if (
            this.goodsList.bargainUser &&
            this.goodsList.bargainUser &&
            this.goodsList.bargainUser.status !== "2"
          ) {
            this.getYMDHMS(this.goodsList.validEndTime);
            setInterval(this.getYMDHMS(this.goodsList.validEndTime), 1000);

            if (
              this.goodsList.status === "1" &&
              this.goodsList.bargainUser &&
              (this.goodsList.bargainUser.status === "1" ||
                this.goodsList.bargainUser.status === "0")
            ) {
              helpCut({
                size: 3,
                current: 1,
                bargainUserId: this.goodsList.bargainUser.id,
              }).then((res) => {
                if (res.data.code === 0) {
                  this.cutFrends = [...res.data.data.records];
                }
              });
            }
          } else {
            this.isLoading = true;
          }
        }
      });
    },

    getGood() {
      if (this.goodsList.bargainUser.isBuy - 0) {
        this.$toast("该活动商品已经购买过一次,请勿再次购买");
        return;
      }
      this.data = {
        type: 1,
        price: this.goodsList.goodsSku.salesPrice.toFixed(2),
        name: this.goodsList.goodsSpu.name,
        subtitle: this.goodsList.shareTitle,
        activeprice: this.goodsList.bargainPrice.toFixed(2),
        relationId: this.goodsList.bargainUser.id,
        skuId: this.goodsList.goodsSku.id,
        spuId: this.goodsList.goodsSpu.id,
        quantity: 1,
        paymentWay: 2,
        deliveryWay: 1,
        img: this.goodsList.picUrl,
      };
      this.$router.push({
        path: "/activeOrder",
        query: {
          data: JSON.stringify(this.data),
        },
      });
    },

    shareArea(val) {
      console.log(this.goodsList);
      this.shareClass.picUrl = this.goodsList.picUrl;
      //  if(this.goodsList.goodsSpu.picUrls.length>0)
      //  {
      //    this.shareClass.picUrl=this.goodsList.goodsSpu.picUrls[0]
      //  }else{
      //   if(this.$store.state.sharePicUrl.length>0)
      //   {
      //     this.shareClass.picUrl=this.$store.state.sharePicUrl[0]
      //   }
      // }
      if (!this.$store.state.session) {
        this.$toast.fail("登录过期 请重新登陆");
        return;
      }

      this.shareClass.num = val;
      this.shareClass.title = this.goodsList.shareTitle;
      this.shareClass.openUrl =
        window.location.href.split("#")[0] +
        `#/ahelpCut?id=` +
        this.$route.query.id +
        `&bargainId=` +
        this.goodsList.id +
        `&bargainUserId=` +
        this.goodsList.bargainUser.id +
        "&token=" +
        this.$store.state.session;
      this.shareClass.url = this.goodsList.picUrl;
      // let u = navigator.userAgent;
      // let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      // let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      console.log();
      try {
        window.webkit.messageHandlers.share.postMessage(
          JSON.stringify(this.shareClass)
        );
      } catch (error) {
        this.shareInfo(this.shareClass);

        //  window.android.share(
        //       this.shareClass.platformId,
        //       '3',
        //       this.shareClass.title,
        //       this.shareClass.content,
        //       this.shareClass.openUrl,
        //       this.share.picUrl
        //     );
      }

      // if (isIOS) {
      //   window.webkit.messageHandlers.share.postMessage(
      //     JSON.stringify(this.shareClass)
      //   );
      // }

      // if (isAndroid) {

      //     window.android.share(
      //       this.shareClass.platformId,
      //       '3',
      //       this.shareClass.title,
      //       this.shareClass.content,
      //       this.shareClass.openUrl,
      //       this.share.picUrl
      //     );

      // }
    },
    buy() {
      (this.data = {
        type: 0,
        price: parseFloat(this.goodSInfo.goodsSku.salesPrice).toFixed(2),
        name: this.goodSInfo.name,
        subtitle: this.goodSInfo.shareTitle,
        activeprice: "",
        relationId: this.goodSInfo.id,
        skuId: this.goodSInfo.goodsSku.id,
        spuId: this.goodSInfo.goodsSpu.id,
        quantity: 1,
        img: this.goodSInfo.picUrl,
        paymentWay: 2,
        deliveryWay: 1,
      }),
        this.$router.push({
          path: "/activeOrder",
          query: {
            data: JSON.stringify(this.data),
          },
        });
    },
  },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },
  beforeDestroy() {
    document.documentElement.style.fontSize = "16px";
    clearInterval(this.time);
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-share-sheet__options {
  display: flex;
  justify-content: space-between;
}
.commodity-spike {
  position: absolute;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  padding-top: 1.45rem;
  box-sizing: border-box;
  background-color: #f6f6f6;
  width: 100%;
}
.commodity-spike::-webkit-scrollbar {
  display: none;
}
.head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.266667rem;
  background-color: white;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
  height: auto;
  z-index: 100;
}
.head .back {
  width: 0.666667rem;
  height: 0.64rem;
}
.head .title {
  flex: 1;
  text-align: center;
  font-size: 0.453333rem;
  font-weight: 600;
  padding-right: 0.666667rem;
}
.main {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  flex: 1;
  height: 100%;
  padding: 0.266667rem 0.293333rem;
  box-sizing: border-box;
  width: 100%;
}
.main::-webkit-scrollbar {
  display: none;
}

.content {
  position: fixed;
  left: 0;
  top: 1.5rem;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.box {
  padding-bottom: 1.066667rem;
}
.content .list {
  width: 90%;
  display: flex;
  margin: auto;
  align-items: center;
  color: white;
  margin-bottom: 0.293333rem;
  overflow-x: auto;
}

.remainning-time {
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  background-color: white;
  padding: 0.266667rem;
  border-radius: 0.133333rem;
  margin-bottom: 0.266667rem;
}
.remainning-time span {
  color: #ff5200ff;
}
.goods-item {
  width: 100%;

  background-color: white;
  border-radius: 0.133333rem;
  margin: 0 auto 0.266667rem;
  padding: 0.373333rem 0.426667rem;
  box-sizing: border-box;
}
.goods-item .title {
  display: flex;
  align-items: center;
}
.title .brand-logo {
  width: 0.666667rem;
  height: 0.666667rem;
}
.title .store-name {
  font-size: 0.4rem;
  font-weight: 600;
  padding-left: 0.266667rem;
}
.good-desc {
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.56rem;
  margin-top: 0.24rem;
  margin-bottom: 0.266667rem;
}
.good-info {
  display: flex;
  font-size: 0.4rem;
}
.good-info .good-img {
  width: 2.426667rem;
  height: 2.426667rem;
  border-radius: 0.266667rem;
  box-sizing: border-box;
  margin-right: 0.266667rem;
}
.desc {
  display: flex;
  flex-direction: column;
}
.desc .good-name {
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: 0.186667rem;
}
.desc .prices {
  display: flex;
  align-items: center;
}
.desc .activeprice {
  color: #a80000;
  font-weight: 600;
  margin-right: 0.266667rem;
}
.specifications {
  flex: 1;
  display: flex;
  width: 4.88rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.453333rem;
  margin-bottom: 0.106667rem;
  align-items: center;
}
.specifications-item {
  display: inline;
  border-right: 0.013333rem solid #999;
  text-align: center;
  padding: 0 0.066667rem;
}
.specifications-item:nth-last-of-type(1) {
  border-right: none;
}
.desc .orginprice {
  color: #999;
  text-decoration: line-through;
  font-weight: 600;
}
.desc .lable {
  background-color: #ff5200ff;
  text-align: center;
  transform: scale(0.83);
  color: white;
  display: block;
  margin-left: 0.266667rem;
}
.join-poeple {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.373333rem;
}
.poeple-num {
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999;
  line-height: 0.453333rem;
}
.rule {
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #005bff;
  line-height: 0.453333rem;
}
.robbing {
  width: 8.24rem;
  height: 0.96rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.4rem;
  font-weight: 500;
  background-color: #e64d42;
  margin: 0.533333rem auto 0.266667rem;
  border-radius: 0.56rem;
}
.time {
  width: 6.72rem;
  height: 0.453333rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 0.453333rem;
  text-align: center;
  width: 100%;
}

.bargain-info {
  padding: 0.4rem 0 0;
  font-size: 0.4rem;
}
.bargain-info .bargain-data {
  padding-left: 0.853333rem;
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}
.bargain-info .bargain-data span {
  color: #ff5200ff;
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;

  line-height: 0.56rem;
}
.proportion {
  margin-left: 0.853333rem;
  width: 80%;
  margin-top: 0.533333rem;
  height: 0.4rem;
  border-radius: 0.213333rem;
  background: #eaeef5;
}
.readyhave {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;

  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.453333rem;
}
.proportion-item {
  position: relative;
  width: 50%;
  height: 100%;
  background-color: #e64d42ff;
  border-radius: 0.213333rem;
  overflow: hidden;
}
.over {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.293333rem;
  height: 0.986667rem;
  color: white;
  background-color: #e64d42;
  border-radius: 0.56rem;
  margin: 0.533333rem auto 0;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.frends {
  padding: 1.146667rem 0.853333rem 0.8rem;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border-radius: 0.266667rem;
  margin: 0.266667rem 0;
}
.frends .title {
  display: flex;
  align-items: center;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}
.frends .left {
  width: 3px;
  height: 0.666667rem;
  background-color: #e64d42ff;
  border-radius: 0.133333rem;
  margin-right: 0.266667rem;
}
.hlep-item {
  display: flex;
  align-items: center;
  padding: 0.373333rem 0;
}
.border {
  border-bottom: 1px solid #f6f6f6;
}
.frends-name {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
}
.frends-help-money {
  flex: 1;
  text-align: right;
  color: #e64d42ff;
  font-size: 0.32rem;
  font-weight: 600;
}
.frends-help-money span {
  font-size: 0.426667rem;
  margin-right: 0.426667rem;
}

.frends-img {
  width: 1.333333rem;
  height: 1.333333rem;
  border-radius: 50%;
  margin-right: 0.266667rem;
}
.footer {
  text-align: center;
  background-color: white;
  border-radius: 0.266667rem;
  padding: 0.266667rem 0;
}
.footer .title {
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.56rem;
  margin-bottom: 0.666667rem;
}
.richtext {
  width: 100%;
  padding: 0 0.426667rem;
  box-sizing: border-box;
  padding-bottom: 0.666667rem;
  display: flex;
  flex-direction: column;
}
.richtext span {
  margin-top: 0.666667rem;
}
.rule-info {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  z-index: 10000;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.rule-box {
  font-size: 0.426667rem;
  background-color: white;
  width: 80%;

  border-radius: 0.133333rem;
  overflow: hidden;
}
.rule-title {
  text-align: center;
  padding: 0.266667rem 0;
}
.rule-title span {
  float: right;
  margin-right: 0.533333rem;
  color: #ff7200ff;
}
.rule-content {
  width: 100%;
  background-color: #ccc;
  box-sizing: border-box;
  padding: 0.266667rem 10%;
  white-space: pre-wrap;
}

/* 
动画
*/
.fade-out-fwd {
  -webkit-animation: fade-out-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }
}
@keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 1;
  }
}
.warp {
  background-color: white;
  border-radius: 0.266667rem;
  box-sizing: border-box;
  padding-bottom: 0.266667rem;
}
</style>