import { fetchGet , fetchPost} from '../../router/https'
function BargainDetail(data){
    return fetchGet("/mallapp/bargaininfo",data)
}
function helpCut(data){ 
    return fetchGet("/mallapp/bargaincut/page",data)
}
function setStatus(data){ 
    return fetchPost("/mallapp/bargainuser",data)
}

export {
   BargainDetail ,
   helpCut,
   setStatus
} 